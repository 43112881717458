"use client";
import Document from "@/components/Document";
import Link from "next/link";
import { FaHome, FaExclamationTriangle } from "react-icons/fa";
import Logo from "@/components/Logo";

export default function NotFound() {
  return (
    <Document locale="en">
      <div className="flex items-center justify-center min-h-screen px-10 bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <div className="absolute top-0 left-0 p-4 text-3xl font-bold text-red-500">
          <FaExclamationTriangle className="inline-block mr-2" />
          404 Not Found
        </div>
        <div className="mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="flex justify-center md:justify-end">
            <Logo/>
          </div>
          <div className="text-center md:text-left">
            <h2 className="text-5xl md:text-6xl font-bold leading-tight mb-6 text-red-400">
              Oops! Page Not Found
            </h2>
            <p className="text-xl md:text-2xl font-light mb-8 text-gray-300">
              The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            </p>
            <Link
              href="/"
              className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-gradient-to-r from-green-400 to-blue-500 rounded-full hover:from-green-500 hover:to-blue-600 transition-all duration-300 shadow-lg hover:shadow-xl">
              <FaHome className="mr-2" />
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </Document>
  );
}
