import React from "react";

const Logo: React.FC = () => {
  return (
    <img 
      src="/imgs/hero.png" 
      alt="Da4ndo Logo" 
      className="w-full h-auto max-w-[200px] sm:max-w-[250px] md:max-w-[300px] lg:max-w-[350px]"
    />
    // <svg
    //   className="w-full h-full"
    //   viewBox="0 0 64 64"
    //   xmlns="http://www.w3.org/2000/svg"
    //   xmlnsXlink="http://www.w3.org/1999/xlink">
    //   <defs>
    //     <mask id="imageMask">
    //       <rect width="100%" height="100%" fill="white" />
    //     </mask>
    //   </defs>
    //   <path d="m34 7c5-.34 8.5 0 15.75 7s7.94 12 8.13 18.25a22.62 22.62 0 0 1 -6.38 16.47c-4.06 4-11.43 9.13-20.9 8.19s-13.6-3.27-18.24-8.91-6.19-14-6.29-17.45 1.69-9.06 6.07-14.12 8-8.43 21.86-9.43z" fill="#1d1d1b"/>
    //   <path d="m30.57 9.24c4.19-.41 8.29-.53 11.88 1.31s8.94 8 9.81 9.06a7.75 7.75 0 0 1 1.28 2.19s-1.75 1.2-1.09 4.25 1.81 4.31 2.31 4.72a4.31 4.31 0 0 0 1.1.63s.31 6.43-1.38 9.87-6.48 9.53-10.78 11.16-11.88 5.06-19.7 1.43-10.14-7.06-13-12.86-3.11-11.64-1.71-15.85 5.91-9.75 9.57-12.25 8.53-3.35 11.71-3.66z" fill="#e6e4da"/>
    //   <path d="m54 23c.18 0 .78 1.78 1.25 3.66a6.62 6.62 0 0 1 .25 2.81 5.71 5.71 0 0 1 -1.91-2.85c-.45-1.76.17-3.62.41-3.62z" fill="#ffffff"/>
    //   <g fill="#1d1d1b">
    //     <path d="m46.42 15.65a10.62 10.62 0 0 1 2.78 2.35c-.06.22.09.81-.22.59s-2.75-2.09-2.81-2.25a.78.78 0 0 1 .25-.69z"/>
    //     <path d="m46.32 18.86a25.44 25.44 0 0 1 3.47 3.14.88.88 0 0 1 -.18.59s-3.41-2.93-3.5-3.06.21-.67.21-.67z"/>
    //     <path d="m45.82 21.24a24.12 24.12 0 0 1 3.35 2.76c0 .28-.1.66-.19.69s-3.72-2.28-3.66-2.56.32-.98.5-.89z"/>
    //     <path d="m27.29 11a2.47 2.47 0 0 1 3.47 2.31 3.26 3.26 0 0 1 -4.31 3c-1.56-.85-1.78-4.31.84-5.31z"/>
    //   </g>
    //   <path d="m27.52 11.78a1.71 1.71 0 0 1 2.4 1.6 2.26 2.26 0 0 1 -3 2.05c-1.06-.54-1.22-2.94.6-3.65z" fill="#ffffff"/>
    //   <path d="m13.2 19.8c.12 0 1.19.53 1.09.63a8.78 8.78 0 0 0 -2.06 3.47c-.5 1.87-.28 2.59-.59 2.62s-1 0-1.07-.19a11.23 11.23 0 0 1 .82-3.33c.53-1.2 1.47-3.07 1.81-3.2z" fill="#1d1d1b"/>
    //   <path d="m30 41.9c1.38-.74 2.78-.29 3.13 1.21a2.5 2.5 0 0 1 -2.41 3.25 2.32 2.32 0 0 1 -.72-4.46z" fill="#1d1d1b"/>
    //   <path d="m30.48 42.8a1.21 1.21 0 0 1 1.94 1.06 1.57 1.57 0 0 1 -2.16 1.54c-1.03-.5-1.09-1.82.22-2.6z" fill="#ffffff"/>
    //   <path d="m20.76 42.52c.13.13 0 .69.75 1.31s1.13.63 1.06.85-.12.47-.34.47a4.06 4.06 0 0 1 -1.5-.94c-.5-.47-1-1.5-.75-1.6a3.06 3.06 0 0 1 .78-.09z" fill="#1d1d1b"/>
    //   <path d="m18.64 42.77a6.38 6.38 0 0 0 1.36 2.13c1 1 1.63 1.21 1.53 1.43s-.37.53-.59.44a14.45 14.45 0 0 1 -2.12-2c-.6-.69-1.22-1.69-1.07-1.84s.89-.16.89-.16z" fill="#1d1d1b"/>
    //   <path d="m16.73 43.05a19.8 19.8 0 0 0 1.88 2.88c1.09 1.22 1.65 1.34 1.59 1.53a.47.47 0 0 1 -.47.37c-.31 0-1.66-1.22-2.62-2.34s-1.47-1.94-1.29-2.09a3.79 3.79 0 0 1 .91-.35z" fill="#1d1d1b"/>
    //   <path d="m39.11 47.43c1.44-.09 2.09.56 1.71 2.22s-1.75 1.5-2.62 1-1.72-3.07.91-3.22z" fill="#1d1d1b"/>
    //   <path d="m39.17 48.24a1 1 0 0 1 .83 1.44.87.87 0 0 1 -1.53 0 1 1 0 0 1 .7-1.44z" fill="#ffffff"/>
    //   <path d="m44.82 35.52c2.51-.59 3.72.19 4.41 2.84s-1.78 4.54-3.72 4.94-2.87.25-3.94-2.19-.21-4.78 3.25-5.59z" fill="#1d1d1b"/>
    //   <path d="m44.54 36.46c.83-.26 2.63-.85 3.35 1.12s.62 3.19-1 4.19-2.32 1.23-3.89-.16-1.83-4.09 1.54-5.15z" fill="#ffffff"/>
    //   <path d="m52.76 32a10.61 10.61 0 0 1 2.16 2.5c0 .25-.16.62-.28.56a16.87 16.87 0 0 1 -2.41-2.37c.03-.23.31-.69.53-.69z" fill="#1d1d1b"/>
    //   <path d="m51.32 33.49c0 .2 3 3.22 3 3.5s-.09.66-.21.59a37.28 37.28 0 0 1 -3.32-3.47c.07-.11.53-.71.53-.62z" fill="#1d1d1b"/>
    //   <path d="m51.17 36.4a21.79 21.79 0 0 1 2.72 3c0 .22-.16.75-.32.68s-3-2.9-3-3 .43-.72.6-.68z" fill="#1d1d1b"/>
    //   <g mask="url(#imageMask)">
    //     <image
    //       xlinkHref="/imgs/da4ndo-mask.png"
    //       x="50%"
    //       y="50%"
    //       width="80%"
    //       height="80%"
    //       preserveAspectRatio="xMidYMid slice"
    //       className="transform -translate-x-[calc(50%-7px)] -translate-y-[calc(50%-6px)]"
    //     />
    //   </g>
    // </svg>
  );
};

export default Logo;

{/* <svg
      className="w-full h-full"
      viewBox="0 0 479 467"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <mask id="blobMask">
          <path
            fill="white"
            d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z"
          />
        </mask>
      </defs>
      <g mask="url(#blobMask)">
        <rect width="100%" height="100%" fill="#3ba55c" />
        <image
          xlinkHref="/imgs/da4ndo.png"
          x="50%"
          y="50%"
          width="80%"
          height="80%"
          preserveAspectRatio="xMidYMid slice"
          className="transform -translate-x-[calc(33.33%+20px)] -translate-y-[calc(50%-30px)]"
        />
      </g>
    </svg> */}