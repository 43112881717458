import '@/styles/globals.css';

import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  locale: string
};

export default function Document({children, locale}: Props) {
  return (
    <html lang={locale} prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb#">
      <body className="bg-slate-900 text-white">{children}</body>
    </html>
  );
}
